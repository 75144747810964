import React from "react";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../LangaugeSelect";


function Tookonefooter() {
  const { t } = useTranslation();



  return (
    <div>
      <div className="tookonefooter px-3">
        <div className="d-md-flex d-block justify-content-between align-items-center text-center text-white tookonefooter-text">
          <p className="text-center pt-md-0 pt-4">
            {t("All right recevied, tookone GmbH")}
          </p>
          <ul className="justify-content-center text-white d-block d-sm-flex text-center">
            <li>
              <a
                href="/tookone/contactus"
                className="text-white text-center text-decoration-none d-flex"
              >
                {t("Contact")}<span className="d-md-block d-none">•</span>
              </a>
            </li>
            <li>
              <a
                href="/tookone/legalinformation"
                className="text-white text-center text-decoration-none d-flex"
              >
                {t("Legal Informations")}<span className="d-md-block d-none">•</span>
              </a>
            </li>
            <li className="d-flex align-items-center">
              {/* <a
                href="/"
                className="text-white text-center text-decoration-none d-flex"
              >
                {t("German")}
              </a> */}
              <div className="p-3 p-md-0"> 
              <LanguageSelect />
              </div>
             
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Tookonefooter;
