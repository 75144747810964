import React from 'react'
import Loaderimg from "../../asset/icons/Iphone-spinner-2.gif"

function Loader() {
  return (
    <div className='position-fixed top-50 start-50 translate-middle'>
        <img src={Loaderimg} alt="" className=''/>
    </div>
  )
}

export default Loader