import { createSlice } from "@reduxjs/toolkit";
export const prefSlice = createSlice({
  name: "prefrence",
  initialState: {
    category: "Visitor",
    brand: "Select",
    childDropDownisVisible: false,
    userAccessToken: "",
    email: "",
    userInfo: {},
    roleinfo: [],
    storeInfo: {},
    storeList: [],
    breandList: [],
  },
  reducers: {
    appCategory: (state, action) => {
      state.category = action.payload;
      // state.childDropDownisVisible = action.payload.falg;
      console.log("category slice :", state.category);
      // console.log("category flag :", state.childDropDownisVisible);
    },
  },
  devTools: true,
});
export const { appCategory } =
  prefSlice.actions;
// export const selectCount = (state) => state.languagekey.languagekey;
export const selectCategory = (state) => state.prefrence.category;

export default prefSlice.reducer;
