import React, { useState } from "react";
import { FaLock } from "react-icons/fa";
import Background from "../../asset/images/bg_image.png";
import logo from "../../asset/images/logo.png";
import { FaKey } from "react-icons/fa";
import { FaAt } from "react-icons/fa";
import * as Yup from "yup";
import LanguageSelect from "../../LangaugeSelect";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { authenticate } from '../../redux/actions/auth';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUserLoginMutation } from "../../redux/services/apiSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Login = () => {
  const { t } = useTranslation();

  const [
    paramsLogin,
    {
      data: LoginData,
      isLoading: isLoadingLogin,
      error: errorLogin,
      isError: isErrorLogin,
      isSuccess: isSuccessLogin,
      reset: resetForm,
    },
  ] = useUserLoginMutation();

  // const state = useSelector(state => state)
  const dispatch = useDispatch();
  const [userValue, setUserValue] = useState();
  console.log(userValue, "userValueuserValue");
  // console.log(state, "statestate")
  const validation = Yup.object({
    email: Yup.string()
      .email("Enter a valid email addres")
      // .isValid(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Enter a valid email addres")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be of 6 characters")
      .required("Password is required"),
    key: Yup.string(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const handleUserValue = (event) => {
    setUserValue({ ...userValue, [event.target.name]: event.target.value });
  };
  console.log(errors, "errorserrors");
  const onSubmit = (data) => {
    paramsLogin(data);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccessLogin && LoginData.TFA === true) {
      toast.success("OTP Sent Successfully on Email");
      navigate(`/admin/login/${LoginData.userId}`);
    } else if (isSuccessLogin && LoginData.TFA === false) {
      sessionStorage.setItem("Token", LoginData.Token);
      toast.success("Login Successfully");
      navigate("/tookone/sharelink");
    }
    resetForm();
  }, [isSuccessLogin]);

useEffect(() => {
  if(errorLogin){
    toast.error(errorLogin.data.msg)
  }
}, [errorLogin])

  return (
    <>
      <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container ">
            <div className="row main align-items-center">
              <div className="col-12 col-md-7 image-section">
                <div className="login-bg position-relative">
                  <img src={Background} alt="logo" className="w-100" />
                  <div>
                    <img
                      src={logo}
                      alt="logo"
                      className="main-logo position-absolute start-50 translate-middle"
                    />
                  </div>
                  <div>
                    <h1 className="text-white position-absolute text-center">
                      <b>{t("think easy")}</b>{" "}
                      <span>{t("experience more")}</span>
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="d-flex justify-content-end mt-3 mt-lg-0 pe-3 mb-4">
                  <LanguageSelect />
                </div>

                <div className="form-group">
                  <div className="position-relative input mb-3">
                    <div className="pass_icon position-absolute ">
                      <FaAt />
                    </div>
                    <input
                      type="text"
                      name="email"
                      placeholder={t("Email")}
                      className="px-4 py-3 bg-transparent border border-2 border-white"
                      {...register("email", { required: true })}
                      onChange={(event) => handleUserValue(event)}
                    />
                    <span style={{ color: "red" }}>
                      {errors?.email?.message}
                    </span>
                  </div>
                  <div className="position-relative input mb-3">
                    <div className="pass_icon position-absolute ">
                      <FaLock />
                    </div>
                    <input
                      type="password"
                      name="password"
                      placeholder={t("Password")}
                      className="px-4 py-3 bg-transparent border border-2 border-white"
                      {...register("password", { required: true })}
                      onChange={(event) => handleUserValue(event)}
                    />
                    <span style={{ color: "red" }}>
                      {errors?.password?.message}
                    </span>
                  </div>
                  <div className="position-relative input mb-3">
                    <div className="pass_icon position-absolute ">
                      <FaKey />
                    </div>
                    <input
                      type="text"
                      name="key"
                      placeholder={t("Key")}
                      className="px-4 py-3 bg-transparent border border-2 border-white"
                    />
                  </div>
                  <div className=" input mb-3">
                    <button type="submit" className="button px-4 py-3">
                      {t("Sign in")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
