import React, { useEffect, useState } from "react";
// import { FaLock } from "react-icons/fa";
import Background from "../../asset/images/bg_image.png";
import logo from "../../asset/images/logo.png";
import { FaKey } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
// import { FaAt } from "react-icons/fa";
// import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// import { authenticate } from "../../redux/actions/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useVerifyOtpMutation } from "../../redux/services/apiSlice";
import { useResendOtpMutation } from "../../redux/services/apiSlice";
import { OtpValidation } from "../../redux/constants/ValidationSchema";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../LangaugeSelect";
import { toast } from "react-toastify";

const OtpForm = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();
  const [
    paramsVerify,
    {
      data: VerifyData,
      isLoading: isLoadingVerify,
      error: errorVerify,
      isError: isErrorVerify,
      isSuccess: isSuccessVerify,
      reset: resetOtpState,
    },
  ] = useVerifyOtpMutation();

  const [
    paramsResend,
    {
      data: ResendData,
      isLoading: isLoadingResend,
      error: errorResend,
      isError: isErrorResend,
      isSuccess: isSuccessResend,
      reset: Resendotp,
    },
  ] = useResendOtpMutation();

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const {
    register: otpRegister,
    handleSubmit: otpSubmit,
    formState: { errors: otpError },
    reset: resetOtpForm,
  } = useForm({
    resolver: yupResolver(OtpValidation),
    mode: "onChange",
  });

  const {
    register: resendResigter,
    handleSubmit: resendsubmit,
    formState: { errors: resenderror },
    reset: ResendOtp,
  } = useForm({
    resolver: yupResolver(Resendotp),
    mode: "onclick",
  });
  console.log(otpError, "errorserrors");
  console.log(resenderror, "errorserrorserrors");

  const [otp, setOtp] = useState();

  const onVerifySubmit = (data) => {
    paramsVerify({ otp: data.otp, userID: id });
  };

  useEffect(() => {
    if (isSuccessVerify) {
      sessionStorage.setItem("Token", VerifyData.Token);
      toast.success(VerifyData.msg);
      navigate("/admin/preregistration");

      resetOtpForm();
    }
  }, [isSuccessVerify]);

  useEffect(() => {
    if (errorVerify) {
      toast.error("Please Enter Valid OTP");
    }
  }, [errorVerify]);

  const handleResend = (data) => {
    paramsResend({ userID: id });
    // console.log("datadta");
  };

  useEffect(() => {
    if (isSuccessResend) {
      toast.success("OTP Resend Successfully");
    }
  }, [isSuccessResend]);

  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
      <div className="container ">
        {console.log(VerifyData, "51561561568151561")}
        <div className="row main align-items-center">
          <div className="col-12 col-md-7 image-section">
            <div className="login-bg position-relative">
              <img src={Background} alt="logo" className="w-100" />
              <div>
                <img
                  src={logo}
                  alt="logo"
                  className="main-logo position-absolute start-50 translate-middle"
                />
              </div>
              <div>
                <h1 className="text-white position-absolute text-center">
                  <b>{t("think easy")}</b> <span>{t("experience more")}</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 pt-5">
            <form onSubmit={otpSubmit(onVerifySubmit)}>
              <div className="form-group">
                <div className="position-relative input mb-3">
                  <div className="pass_icon position-absolute ">
                    <FaKey />
                  </div>
                  <input
                    {...otpRegister("otp")}
                    type="text"
                    name="otp"
                    value={otp}
                    placeholder={t("OTP")}
                    className="px-4 py-3 bg-transparent border border-2 border-white"
                  />
                  <span style={{ color: "red" }}>{otpError?.otp?.message}</span>
                </div>

                <div className=" input mb-3">
                  <button className="button px-4 py-3">{t("Submit")}</button>
                </div>
                <div className="otp">
                  <Link to="" onClick={handleResend} className="Resendbutton">
                    {t("Resend Otp")}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpForm;
