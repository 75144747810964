import React from "react";
import AdminHeader from "./adminHeader";
import NetworkIcon from "../../asset/icons/network.svg";
import CloudkIcon from "../../asset/icons/cloud.svg";
import Contect from "../../asset/icons/Contect.svg";
import Round_dot from "../../asset/icons/round_dot.svg";
import Pen_icon from "../../asset/icons/pen-icon.svg";
import envalop_icon from "../../asset/icons/envalop.svg";
import check_icon from "../../asset/icons/checkicon.svg";
import user_icon from "../../asset/icons/user.svg";
import { FaBars } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { MdAppRegistration } from "react-icons/md";
import { useGetAllContactMutation, useDeleteContactRequestMutation, useArchiveMutation, useGetEditMutation } from "../../redux/services/apiSlice";
import moment from "moment";
import PrivateRoute from "../../Components/PrivateRoute";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DateSchema } from "yup";


function ContactRequest() {

  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [Notice, setNoitce] = useState();
  const handleClose = () => setShow(true);
  const handleShow = () => setShow(true);

  const [
    paramsAllContact,
    {
      data: GetallContact,
      isLoading: isLoadingGetallContact,
      error: errorGetallContact,
      isError: isErrorGetallContact,
      isSuccess: isSuccessGetallContact,
    },
  ] = useGetAllContactMutation();

  const [
    paramsDeleteContact,
    {
      data: DeleteData,
      isLoading: isLoadingDelete,
      error: errorDelete,
      isError: isErrorDelete,
      isSuccess: isSuccessDelete,
    },
  ] = useDeleteContactRequestMutation();

  const [
    paramsArchive,
    {
      data: ArchiveData,
      isLoading: isLoadingArchive,
      error: errorArchive,
      isError: isErrorArchive,
      isSuccess: isSuccessArchive,
    },
  ] = useArchiveMutation();

  const [
    paramsEdit,
    {
      data: Edit,
      isLoading: isLoadingEdit,
      error: errorEdit,
      isError: isErrorEdit,
      isSuccess: isSuccessEdit,
    },
  ] = useGetEditMutation();


  const params = useParams();
  const { _id } = params;

  const [roundDot, setRoundDot] = useState(null)

  const getAllcontact = (data) => {
    paramsAllContact(data);
  };

  useEffect(() => {
    getAllcontact();
  }, []);


  const countuser = (data) => {
    let paramToReturn = 0;
    for (let i = 0; i <= GetallContact?.contact.length; i++) {
      if (GetallContact?.contact[i]?.status === data) {
        paramToReturn += 1;
      }
    }
    return paramToReturn;
  };

  // const handleEdit = (_id, item) => {
  //   console.log("ididididi", _id, item);
  // }

  const handleDelete = (_id) => {
    paramsDeleteContact(_id)
    if (isSuccessDelete) {
      window.location.reload()
      // toast.success("Delete Successfully")

    }
  }

  const handleArchive = (_id) => {
    paramsArchive(_id)
    if (isSuccessArchive) {
      window.location.reload()
      toast.success("{Archive Successfully")
    }
  }

  const handleRoundDot = (_id) => {
    console.log(GetallContact.contact, "000000");
    if (roundDot === _id) {
      setRoundDot(null)
    } if (roundDot !== _id) {
      setRoundDot(_id);
    }
    console.log("ididididi", _id);
  }

  const handleedit = () => {
    console.log("Clicked");
  }

  const handleUserValue = (event) => {
    setNoitce({ ...Notice, [event.target.name]: event.target.value });
  }


  const handlesubmit = (_id) => {
    paramsEdit({ id: _id, notice: Notice.notice })
    if (isSuccessEdit) {
      window.location.reload()
    }
  }

  const navigate = useNavigate()
  const handleGlobe=()=>{
    navigate("/admin/preregistration")
  }


  return (
    <PrivateRoute>
      <div>
        <AdminHeader />
        {/* {console.log(GetallContact, "bnuibfkjcfbnkjnfokjidfn")} */}
        <div className="admin-container">
          <div className="header">
            <div className="container-fluid">
              <div className="row w-100 border-bottom border-dark">
                <div className="header-icon d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="col-12 col-md-5">
                      {/* Toggle */}
                      <div className="d-flex align-items-center justify-content-between icons">
                        <div className="menu-bar text-white d-lg-none d-block" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                          <FaBars />
                        </div>
                        <div className="header_icon">
                          <img onClick={handleGlobe} src={NetworkIcon} alt="Blue_Network" />
                        </div>
                        <div className="header_icon Cloudkicon">
                          <img src={CloudkIcon} alt="CloudkIcon" />
                          <h3 className="icontext">{t("XRM")}</h3>
                        </div>

                      </div>
                    </div>
                    {/* <div className="col-md-5 col-12">
                   
                  </div> */}
                  </div>
                </div>
              </div>
              <div className=" d-flex align-items-center mt-2 contect-req-title profile-ico">
                <img src={Contect} alt="contect_icon" />
                <p className="text-light text-white">{t("Contact Request")}</p>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="buttons requestbuttons">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="first-button d-flex align-items-center justify-content-md-between justify-content-center">
                    {countuser("processed")} Processed
                    <img src={check_icon} alt="contect icon" />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="second-button d-flex align-items-center justify-content-md-between justify-content-center">
                    {countuser("being processed")} Being processed
                    <img src={Pen_icon} alt="contect icon" />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="thread-button align-items-center d-flex align-items-center justify-content-md-between justify-content-center">
                    {countuser("open")} open
                    <img src={envalop_icon} alt="contect icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="game_from">
            {GetallContact?.contact.map((item, index) => {
              return (
                <div className="container-fluid" key={index}>
                  <div className="row align-items-center">
                    <div className="col-md-2 col-xl-1 col-12">
                      <p className="text-center">{index + 1}</p>
                      <h3 className="game-text">{t("Game Form")}</h3>
                    </div>
                    <div className="col-12 col-md-10 col-xl-11">
                      <div className="data-input text-start">
                        <p>{item.message}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className=" col-xl-1 col-lg-2 col-12 ">
                      <div className="col-12 text-center"><span>#010239</span></div>
                      <div className="small-button d-md-flex">
                        <div className="col-md-6 col-12">
                          {item.status === "processed" ? (
                            <div className="btn-1">
                              <span></span>
                            </div>
                          ) : item.status === "being processed" ? (
                            <div className="btn-3">
                              <span></span>
                            </div>
                          ) : (
                            <div className="btn-4">
                              <span></span>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="btn-2 mt-2 mt-md-0 position-relative">
                            <span className="">
                              <img src={Round_dot} onClick={() => handleRoundDot(item._id)} alt="networkicon" />
                            </span>

                            {item._id === roundDot && (<div className="position-absolute option">
                              <div className="w-100 text-center cursor-pointer	">
                                {/* <button data-toggle="modal" data-target="#exampleModal"></button> */}
                                {/* <h5 onClick={() => handleEdit(item._id, item)}>Edit</h5> */}
                                <h5 onClick={() => handleArchive(item._id, item)}>Archive</h5>
                                <h5 onClick={() => handleDelete(item._id)}>Delete</h5>
                              </div>
                            </div>)}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-11 col-lg-10 col-12">
                      <div className="row text-section">
                        <div className="col-xl-6 col-12">
                          <div className="row">
                            <div className="col-md-6 logo-section d-flex align-items-center">
                              <div className="logo">
                                <span>JS</span>
                              </div>
                              <div className="text">
                                <p className="name text-left">{t("name")}</p>
                                <h5 className="block">{item.name}</h5>
                              </div>
                            </div>
                            <div className="col logo-section d-flex align-items-center">
                              <div className="text">
                                <p className="company text-left">
                                  {t("company")}
                                </p>
                                <h5 className="block">abc</h5>
                              </div>
                            </div>
                            <div className="col logo-section d-flex align-items-center">
                              <div className="text">
                                <p className="phone text-left">{t("phone")}</p>
                                <h5 className="block">{item.number}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-xl-6 col-12">
                          <div className="row">
                            <div className="col logo-section d-flex align-items-center section-2">
                              <div className="text">
                                <p className="gmail text-left">{"gmail"}</p>
                                <h5 className="block">{item.email}</h5>
                              </div>
                            </div>
                            <div className="col logo-section d-flex align-items-center">
                              <div className="text">
                                <p className="data text-left">{"date"}</p>
                                <h5 className="block">
                                  {moment(item.createdAt).format("DD-MM-YYYY")}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-6  logo-section  col-12  d-flex align-items-center">
                              <div className="text ">
                                <p className="notice text-left">
                                  {t("notice")}
                                </p>
                                <div className="d-flex align-items-center  justify-content-between">
                                  <input className="block notice-input" type="text" defaultValue={item.notice}
                                    onChange={(event) => handleUserValue(event)} name="notice" >
                                  </input>
                                  <button type="submit" className="btn btn-primary notice-input-btn" onClick={() => handlesubmit(item._id)}>Submit</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="admin-sidebar-container d-lg-block d-none">
          <div className="container-fluid">
            <div className="border-bottom">
              <div className="row align-center pt-2 pb-1">
                <div className="col-xl-3 col-12">
                  <div className="use-icon d-xl-block d-flex justify-content-center align-items-center">
                    <img src={user_icon} alt="user_icon" />
                  </div>
                </div>
                <div className="col-xl-9  logo-text col-12">
                  <p className="text-white text-center pe-2">
                    Pre-registrations
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </PrivateRoute>
  );
}

export default ContactRequest;
