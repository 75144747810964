import React from "react";
import Mobil_img from "../../asset/images/tookone/Mask Group 2.png";
import box from "../../asset/icons/box-icon.svg";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import Footer from "../tookone/Tookonefooter";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useGetAllMessageMutation } from "../../redux/services/apiSlice";
import { toast } from "react-toastify";
import AdminHeader from "./../admin/adminHeader";

function ContactUs() {
  const { t } = useTranslation();

  const [MessageValue, setMessageValue] = useState();

  const [
    paramsMessage,
    {
      data: MessageData,
      isLoading: isLoadingMessage,
      error: errogMessage,
      isError: isErrogMessage,
      isSuccess: isSuccesgMessage,
      reset: resetForm,
    },
  ] = useGetAllMessageMutation();

  const handlemessage = (event) => {
    setMessageValue({
      ...MessageValue,
      [event?.target.name]: event?.target.value,
    });
  };

  const handleSubmit = () => {
    paramsMessage(MessageValue);
  };

  useEffect(() => {
    handlemessage();
  }, []);

  useEffect(() => {
    if (isSuccesgMessage) {
      toast.success("Message Sent Success");
    }
  }, [isSuccesgMessage]);

  return (
    <div>
      {/* <AdminHeader/> */}
      <div className="background_img contect_header">
        <div className="legalinformation container-xxl">
          <h1 className="text-white">{t("CONTACT US")}</h1>
        </div>
      </div>
      <div className="container-xxl">
        <div className="contact-section">
          <div className="row">
            <div className="col-md-6 col-12 text-section pt-3">
              <h5 className="text-end ">
                {t("We look forword to your")} <span>{t("inquiries!")}</span>
              </h5>
              <p>
                {t(
                  "Do you have any Questions, would you like to send us Feedback or"
                )}
                {t(" are ")}
                <br />
                {t(
                  "you Looking to contact us? Use our form to contact us."
                )}{" "}
                <br />
                {t("We look Forward to Hearing from you.")}
              </p>

              <div className="contact_info">
                <div className="loction d-flex align-items-center">
                  <FaMapMarkerAlt />
                  <p>{t("Am Firstberg 78098 Triberg")}</p>
                </div>
                <div className="contact_number d-flex  align-items-center">
                  <FaPhoneAlt />
                  <p>+49 (0) 7722/86 99 16</p>
                </div>
                <div className="Email d-flex  align-items-center">
                  <FaEnvelope />
                  <p>{t("info@tookone.de")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div>
                <img
                  src={Mobil_img}
                  alt="user_icon"
                  className="d-flex position-relative "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-bg">
        <div className="container-xxl">
          <div className="row flex-column-reverse flex-md-row justify-content-md-between">
            <div className="contact_form col-md-6  col-12">
              <input
                type="text"
                name="name"
                onChange={(event) => handlemessage(event)}
                placeholder={t("your name")}
              />
              <input
                type="text"
                name="email"
                onChange={(event) => handlemessage(event)}
                placeholder={t("your email")}
              />
              <input
                type="number"
                name="number"
                onChange={(event) => handlemessage(event)}
                placeholder={t("your phone number")}
              />
              <textarea
                placeholder={t(
                  "Write us a message and we will get back to you soon"
                )}
                onChange={(event) => handlemessage(event)}
                name="message"
              ></textarea>
              <button onClick={handleSubmit}>{t("Submit")}</button>
            </div>
            <div className="col-md-5 col-12 ">
              <div className="contact_text">
                <h1 className="text-white text-md-start text-center">
                  {t("Curious?")}
                </h1>
                <div className="text-white">
                  <p className="text-md-start text-center">
                    {t(
                      "Email, phone or in Person, we are loking Forward to you!"
                    )}
                  </p>
                  <span className="d-block text-md-start text-center">
                    {" "}
                    {t(
                      "We will be happy to call you, just send us a short message"
                    )}
                    {t("with your telephone number.")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="box-icon d-md-block d-none">
            <img src={box} alt="box" className="" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
