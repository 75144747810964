import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "../../scss/scss/pages/admin/adminHeader.scss";
import logo from "../../asset/images/logo.png";
import { useTranslation } from "react-i18next";
import {  useNavigate } from "react-router-dom";

function Adminheader() {
  const navigate = useNavigate()
  const { t } = useTranslation();

  const handlelogo = ()=>{
    navigate("/")
  }
  return (
    <div>
      <header>
        <div className="main-header align-items-center d-flex justify-content-center ">
          <div className="container-fluid">
            <div className="nav-bar align-items-center d-flex justify-content-between">
              <div className="logo">
                <img onClick={handlelogo} src={logo} className="" alt="" />
              </div>
              <div className="nav">
                <input
                  type="text"
                  name="name"
                  placeholder={t("What would you like to look for")}
                  className="search bg-transparent"
                />
              </div>
              <div className="dropdown-section">
                <Dropdown>
                  <Dropdown.Toggle
                    className="bg-transparent dropdown header_dropdown"
                    id="dropdown-basic"
                  >
                    {t("Admin")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      {t("Action")}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      {t("Another action")}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      {t("Something else")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Adminheader;
