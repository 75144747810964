import * as Yup from "yup";

export const LoginValidation = Yup.object({
    email: Yup.string()
      .email("Enter a valid email addres")
      // .isValid(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Enter a valid email addres")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be of 6 characters")
      .required("Password is required"),
    key: Yup.string(),
  });
  export const OtpValidation = Yup.object({
    otp: Yup.string()
      .min(3, "Otp must be of 4 characters")
      .required("Otp is required"),

  });