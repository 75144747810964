import React from "react";
import flag1 from "../../asset/images/flag1.png";
import flag2 from "../../asset/images/flag2.png";
import flag3 from "../../asset/images/flag3.png";
import flag4 from "../../asset/images/flag4.png";
import book_image from "../../asset/images/book.png";
import star from "../../asset/images/bg_vector.png";
import girls_image from "../../asset/images/girls.png";
import file_image from "../../asset/images/file.png";
import green_star from "../../asset/images/greenstar.png";
import cartoon_image from "../../asset/images/cartoon_image.png";
import shape_image from "../../asset/images/shape.png";
import emoji from "../../asset/images/emoji.png";
import emoji_image from "../../asset/images/emoji_image.png";
import Tookonefooter from "../tookone/Tookonefooter";
import blue_star from "../../asset/images/star.png";
import cartoon_img from "../../asset/images/cartoon.png";
import bluestar from "../../asset/images/star-image.png";
import { useNavigate, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import i18next from "i18next";

import { FaAt, FaUserPlus, FaShareSquare } from "react-icons/fa";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import LanguageSelect from "../../LangaugeSelect";

function Kickstarter() {
  const params = useParams();
  const { item } = params;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClick = () => {
    // let path="https://www.kickstarter.com/projects/took/tookone-colorsplash-are-you-the-king-or-the-poo"
    navigate("https://www.kickstarter.com/projects/took/tookone-colorsplash-are-you-the-king-or-the-poo");
  };
  const handlegamebutton = () => {
    navigate("/game/gamemanual");
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCopy = () => {
    toast.success("Copied To Clipboard");
  };

  return (
    <div className="kickstarter">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-12   lang-main">
            <div className="text-light position-relative  d-flex justify-content-center pt-md-0 pt-4">
              <div className="star">
                <img src={star} alt="" />
              </div>
              <h2>{t("Language")}</h2>
            </div>
            <div className="flag_main row my-4 mb-0">
              <div className="col-lg-3 col-6 pb-4 pb-lg-0  pt-4 d-flex flag_img  border-round">
                <img
                  className="border-round"
                  src={flag1}
                  onClick={() => {
                    i18next.changeLanguage("en");
                  }}
                />
              </div>

              <div className="col-lg-3 col-6 pb-4 pb-lg-0  pt-4 d-flex flag_img border-round">
                <img
                  src={flag2}
                  className="border-round"
                  onClick={() => {
                    i18next.changeLanguage("es");
                  }}
                />
              </div>
              <div className="col-lg-3 col-6 pb-4 pb-lg-0  pt-4 d-flex flag_img border-round">
                <img
                  src={flag3}
                  className="border-round"
                  onClick={() => {
                    i18next.changeLanguage("fr");
                  }}
                />
              </div>
              <div className="col-lg-3 col-6 pb-4  pb-lg-0 pt-4 d-flex flag_img border-round">
                <img
                  src={flag4}
                  className="border-round"
                  onClick={() => {
                    i18next.changeLanguage("de");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 p-0">
            <div className="hip_hard">
              <div className="book_img">
                <img src={book_image} alt="" />
              </div>
              <p>
                {t(
                  "The new hip card game 2022 for friends and family. Play to see who is the king and whi is the poo! Are you ready?"
                )}
              </p>
            </div>
          </div>
          <div className="col-md-4 col-12 p-0">
            <div className="game">
              <p>
                {t(
                  "The game is hilarious! My family and I had a blast playing!"
                )}
              </p>
              <div className="book_img blue">
                <img src={girls_image} alt="" />
                <img src={bluestar} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="original">
              <h5>{t("The original - tookone colorsplash")}</h5>
              <div className="all_image">
                <div className="image_file">
                  <img src={green_star} alt="" />
                  <img src={file_image} alt="" />
                </div>
                <img className="image_cartoon" src={cartoon_image} alt="" />
              </div>
              <div className="game_manual">
                <button onClick={handleShow}>{t("Recommend")}</button>
                
                <a href="https://www.kickstarter.com/projects/took/tookone-colorsplash-are-you-the-king-or-the-poo">
                <img src={shape_image} alt="" />
                </a>
                <button onClick={handlegamebutton}>{t("Game manual")}</button>
              </div>
              <div className="king">
                <img src={emoji} alt="" />
                <h4>{t("Are you the KING or the POO")} </h4>
                <img src={emoji_image} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-0">
            <div className="color_splash">
              <div>
                <h5>{t("Colorsplash with Action extension")}</h5>
                <div className="image_section">
                  <img src={file_image} alt="" />
                  <div className="cartton_img">
                    <img src={file_image} alt="" />
                    <img src={cartoon_img} alt="" />
                  </div>
                </div>
              </div>
              <div className="star_image">
                <img src={blue_star} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-bar">
        <Offcanvas show={show} onHide={handleClose} className="right-0 top-0">
          <div className="side-bar">
            <Offcanvas.Title className="ms-4 d-flex align-items-center">
              <FaShareSquare /> <p className="title">{t("Share this Link")}</p>
              <Offcanvas.Header
                closeButton
                className="ms-auto"
              ></Offcanvas.Header>
            </Offcanvas.Title>
          </div>
          <Offcanvas.Body>
            <div className="link">
              <CopyToClipboard text="https://took.me/567jdgr">
                <p id="copylink">
                  https://took.me/567jdgr
                  <button className="ms-auto" onClick={HandleCopy}>
                    {t("copy")}
                  </button>
                </p>
              </CopyToClipboard>
            </div>
            <div className="social-icon d-flex gap-3">
              <div className="icon">
                <FacebookShareButton url="https://took.me/567jdgr">
                  <FacebookIcon className="rounded-circle" />
                </FacebookShareButton>
              </div>
              <div className="icon">
                <LinkedinShareButton url="https://took.me/567jdgr">
                  <LinkedinIcon className="rounded-circle" />
                </LinkedinShareButton>
              </div>
              <div className="icon">
                <TwitterShareButton url="https://took.me/567jdgr">
                  <TwitterIcon className="rounded-circle" />
                </TwitterShareButton>
              </div>
              <div className="icon">
                <TelegramShareButton url="https://took.me/567jdgr">
                  <TelegramIcon className="rounded-circle" />
                </TelegramShareButton>
              </div>
              <div className="icon">
                <WhatsappShareButton url="https://took.me/567jdgr">
                  <WhatsappIcon className="rounded-circle" />
                </WhatsappShareButton>
              </div>
              <div className="icon">
                <EmailShareButton url="https://took.me/567jdgr">
                  <EmailIcon className="rounded-circle" />
                </EmailShareButton>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <Tookonefooter />
    </div>
  );
}

export default Kickstarter;
