import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Dropdown from "react-bootstrap/Dropdown";
import flag1 from "../src/asset/images/flag1.png";
import flag2 from "../src/asset/images/flag2.png";
import flag3 from "../src/asset/images/flag3.png";
import flag4 from "../src/asset/images/flag4.png";

const languageMap = {
  en: { label: "English", dir: "ltr", active: true, Image: { flag1 } },
  de: { label: "DEUTSCH", dir: "rtl", active: false, Image: { flag4 } },
  fr: { label: "FRANÇAISE", dir: "ltr", active: false, Image: { flag3 } },
  es: { label: "ESPAÑOLA", dir: "ltr", active: false, Image: { flag2 } },
};

const LanguageSelect = () => {
  
  const selected = localStorage.getItem("i18nextLng") || "en";
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      // document.body.dir = languageMap[selected].dir;
    }
  }, [selected]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="bg-transparent border border-white shadow-none "
        id="dropdown-basic"
      >
        {t("select_language")}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.keys(languageMap)?.map((item) => (
          <Dropdown.Item
            button
            key={item}
            onClick={() => {
              i18next.changeLanguage(item);
            }}
          >
            {languageMap[item].label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelect;
