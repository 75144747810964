import React, { useState } from "react";
import Logo from "../../asset/images/logo.png";
import { FaAt, FaUserPlus, FaShareSquare } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import box_image from "../../asset/images/tookone/box_image.png";
import icons1 from "../../asset/images/demo1.png";
import icons2 from "../../asset/images/demo2.png";
import icons3 from "../../asset/images/demo03.png";
import icons4 from "../../asset/images/demo04.png";
import icons5 from "../../asset/images/demo05.png";
import icons6 from "../../asset/images/demo06.png";
import icons7 from "../../asset/images/demo7.png";
import icons8 from "../../asset/images/demo8.png";

import Share from "../../asset/icons/share-svgrepo-com.svg";
import Footer from "../tookone/Tookonefooter";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { usePreregisterMutation } from "../../redux/services/apiSlice";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import Loader from "../admin/Loader";

function Index() {
  const navigate = useNavigate();

  const [
    paramsPreregister,
    {
      data: preregister,
      isLoading: isLoadingResigster,
      error: errorResigster,
      isError: isErrorResigster,
      isSuccess: isSuccessResigster,
      reset: resetResigsterForm,
    },
  ] = usePreregisterMutation();
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState();
  const [toggle, setToggle] = useState(false);

  const handleEmail = (event) => {
    setUserEmail({ ...userEmail, [event.target.name]: event.target.value });
  };
  const onSubmit = ({ email }) => {
    paramsPreregister({ email });
  };
  useEffect(() => {
    if (isSuccessResigster) {
      toast.success(preregister.msg);
      navigate("/game/kickstarter");
    }
  }, [isSuccessResigster]);
  useEffect(() => {
    if (isErrorResigster) {
      toast.error(errorResigster.data.msg);
    }
  }, [isErrorResigster]);

  useEffect(() => {
    if (isLoadingResigster) {
      <Loader />;
    }
  }, [isLoadingResigster]);

  const validation = Yup.object({
    email: Yup.string()
      .email("Enter a valid email address")
      // .isValid(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Enter a valid email addres")
      .required("Email is required"),
    // key: Yup.string(),
    // key: Yup.string(),
    acceptTerms: Yup.bool().oneOf([true], "Accept Ts & Cs is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
    mode: "onChange",
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCopy = () => {
    toast.success("Copied To Clipboard");
  };

  const handlesupport = () => {
    navigate("/game/kickstarter");
  };

  return (
    <>
      <div className="index">
        <div className="background">
          <div className="row w-100 justify-content-lg-between h-100 m-0">
            <div className="col-lg-7 col-12 text-white logo-section position-relative">
              <div className="position-absolute share_icon">
                <img src={Share} onClick={handleShow} />
              </div>
              <div className="indexlogo text-center">
                <img src={Logo} alt="logo" />
                <h1>{t("COMING SOON 2022")}</h1>
                <p>
                  {t("think easy")} <span>{t("experience more")}</span>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-12 text-white p-lg-0 position-relative pb-0 tooken-section">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pre-register d-flex justify-content-between flex-column ms-md-auto">
                  <h4 className="text-center">
                    {t(
                      "Curious? You can pre-register, we will send you an email as"
                    )}
                    {t("soon as tookone is online")}
                  </h4>
                  <div className="position-relative input mt-4">
                    <div className="icon position-absolute ">
                      <FaAt />
                    </div>
                    <input
                      type="text"
                      name="email"
                      placeholder={t("Email")}
                      {...register("email", { required: true })}
                      // onChange={(event) => handleEmail(event)}
                      className="px-4 py-3 bg-transparent border border-2 border-white"
                    />
                    <span style={{ color: "red" }}>
                      {errors?.email?.message}
                    </span>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        onChange={(event) => setToggle(!toggle)}
                        // checked={toggle}
                        {...register("acceptTerms", { required: true })}
                        type="switch"
                        id="custom-switch"
                      />
                      <p>
                        {t("I have read and agree to the")}{" "}
                        <span>{t("Privacy Policy")}</span>
                      </p>
                    </div>
                    <span style={{ color: "red" }}>
                      {errors?.acceptTerms?.message}
                    </span>
                  </div>

                  <button
                  disabled={isLoadingResigster}
                    type="submit"
                    style={{
                      background:isLoadingResigster?"grey":""
                    }}
                    className={"w-100 text-center d-flex align-items-center justify-content-center"}
                  >
                    <FaUserPlus className="fs-5 me-2" />
                    {t(" Pre-register")}
                  </button>
                </div>
              </form>
              <div className="Recommend">
                <h4 className="text-center">
                  {t("Let's create something that changes everything!")}
                </h4>
                <h3 className="text-center">
                  {t(" Are you ready to break the rules?")}
                </h3>
                <button
                  onClick={handleShow}
                  className="w-100 mb-2 text-center d-flex align-items-center justify-content-center"
                >
                  <FaShareSquare className="fs-5 me-2" /> {t("Recommend")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-tooken pt-lg-0">
        <div className="row w-100 m-0 ">
          <div className="col-xl-4 col-lg-5 col-12 p-0 pe-1">
            <div className="product" onClick={handlesupport}>
              <h1 className="text-center">{t("You want to support us?")}</h1>
              <div className="row align-items-center mt-5 d-flex w-100">
                <div className="product-img col-6 ">
                  <img src={box_image} alt="box-imge" className="w-100" />
                </div>
                <div className="text col-6 p-0 m-0   product-text">
                  <p className="text-center">{t("tookone colorsplash")}</p>
                  <h1 className="text-center mb-5 ">{t("OUT NOW")}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-8 col-12 p-0 ">
            {/* <img src={Logo_Img} className="h-100" alt="logo" /> */}
            <div className="icons_box">
              <div className="part01">
                <div className="icons_image">
                  <img src={icons1} alt="" />
                  <span>awards</span>
                </div>
                <div className="icons_image">
                  <img src={icons2} alt="" />
                  <span>hashs</span>
                </div>
                <div className="icons_image">
                  <img src={icons3} alt="" />
                  <span>informations</span>
                </div>
                <div className="icons_image">
                  <img src={icons4} alt="" />
                  <span>interests</span>
                </div>
              </div>
              <div className="part02">
                <div>
                  <h2>ALL IN ONE PLACE</h2>
                  <div className="daimod">
                    <div className="icons_image">
                      <img src={icons5} alt="" />
                      <span>inspiration</span>
                    </div>
                    <div className="icons_image">
                      <img src={icons6} alt="" />
                      <span>hashtags</span>
                    </div>
                    <div className="icons_image">
                      <img src={icons7} alt="" />
                      <span>gifts</span>
                    </div>
                  </div>
                </div>
                <div className="icons_image">
                  <img src={icons8} alt="" />
                  <span>pixels</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-bar">
        <Offcanvas show={show} onHide={handleClose} className="right-0 top-0">
          <div className="side-bar">
            <Offcanvas.Title className="ms-4 d-flex align-items-center">
              <FaShareSquare /> <p className="title">{t("Share this Link")}</p>
              <Offcanvas.Header
                closeButton
                className="ms-auto"
              ></Offcanvas.Header>
            </Offcanvas.Title>
          </div>
          <Offcanvas.Body>
            <div className="link">
              <CopyToClipboard text="https://took.me/567jdgr">
                <p id="copylink">
                  https://took.me/567jdgr
                  <button className="ms-auto" onClick={HandleCopy}>
                    {t("copy")}
                  </button>
                </p>
              </CopyToClipboard>
            </div>
            <div className="social-icon d-flex gap-3">
              <div className="icon">
                <FacebookShareButton url="https://took.me/567jdgr">
                  <FacebookIcon className="rounded-circle" />
                </FacebookShareButton>
              </div>
              <div className="icon">
                <LinkedinShareButton url="https://took.me/567jdgr">
                  <LinkedinIcon className="rounded-circle" />
                </LinkedinShareButton>
              </div>
              <div className="icon">
                <TwitterShareButton url="https://took.me/567jdgr">
                  <TwitterIcon className="rounded-circle" />
                </TwitterShareButton>
              </div>
              <div className="icon">
                <TelegramShareButton url="https://took.me/567jdgr">
                  <TelegramIcon className="rounded-circle" />
                </TelegramShareButton>
              </div>
              <div className="icon">
                <WhatsappShareButton url="https://took.me/567jdgr">
                  <WhatsappIcon className="rounded-circle" />
                </WhatsappShareButton>
              </div>
              <div className="icon">
                <EmailShareButton url="https://took.me/567jdgr">
                  <EmailIcon className="rounded-circle" />
                </EmailShareButton>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <Footer />
    </>
  );
}

export default Index;
