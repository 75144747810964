import React from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../pages/admin/Loader";
import { useGetStatusMutation } from "../redux/services/apiSlice";

const PrivateRoute = ({ children }) => {
  const [
    paramsAllStatus,
    {
      data: GetallStatus,
      isLoading: isLoadingGetallStatus,
      error: errorGetallStatus,
      isError: isErrorGetallStatus,
      isSuccess: isSuccessGetallStatus,
    },
  ] = useGetStatusMutation();

  useEffect(() => {
    paramsAllStatus();
  }, []);

  useEffect(() => {
    if (errorGetallStatus) {
      toast.error(errorGetallStatus.data.msg)
    }
  }, [isErrorGetallStatus]);

  return (
    <>
      {!isLoadingGetallStatus &&
      isSuccessGetallStatus &&
      GetallStatus.status ? (
        children
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PrivateRoute;
