import React, { useState } from "react";
import Index from "../tookone/index";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaShareSquare } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

function ShareLink() {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCopy = () => {
    alert("Copied To Clipboard");
  };

  return (
    <div>
      <Index />
      <div className="side-bar">
        <Button variant="primary" onClick={handleShow}>
          {t("Launch")}
        </Button>

        <Offcanvas show={show} onHide={handleClose} className="right-0 top-0">
          <div className="side-bar">
            <Offcanvas.Title className="ms-4 d-flex align-items-center">
              <FaShareSquare /> <p className="title">{t("Share this Link")}</p>
              <Offcanvas.Header
                closeButton
                className="ms-auto"
              ></Offcanvas.Header>
            </Offcanvas.Title>
          </div>
          <Offcanvas.Body>
            <div className="link">
              <CopyToClipboard text="https://took.me/567jdgr">
                <p id="copylink">
                  https://took.me/567jdgr
                  <button className="ms-auto" onClick={HandleCopy}>
                    {t("copy")}
                  </button>
                </p>
              </CopyToClipboard>
            </div>
            <div className="social-icon  d-flex gap-2">
              <div className="icon">
                <FacebookShareButton url="https://took.me/567jdgr">
                  <FacebookIcon />
                </FacebookShareButton>
              </div>
              <div className="icon">
                <LinkedinShareButton url="https://took.me/567jdgr">
                  <LinkedinIcon />
                </LinkedinShareButton>
              </div>
              <div className="icon">
                <TwitterShareButton url="https://took.me/567jdgr">
                  <TwitterIcon />
                </TwitterShareButton>
              </div>
              <div className="icon">
                <TelegramShareButton url="https://took.me/567jdgr">
                  <TelegramIcon />
                </TelegramShareButton>
              </div>
              <div className="icon">
                <WhatsappShareButton url="https://took.me/567jdgr">
                  <WhatsappIcon />
                </WhatsappShareButton>
              </div>
              <div className="icon">
                <EmailShareButton url="https://took.me/567jdgr">
                  <EmailIcon />
                </EmailShareButton>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
}

export default ShareLink;
