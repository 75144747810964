import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  DEVELOPMENT_MODE_BASEURL,
  // GetBaseURL,
  URLs,
  // XDEBUG_SESSION_START_FLAG,
  // ZIPCODE__DEMO_API_KEY,
} from "../constants/ApiConstants";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: DEVELOPMENT_MODE_BASEURL,

    prepareHeaders: (header, { getState, endpoint }) => {
      header.set("Accept", "application/json");
      header.set("platform", "web");

      console.log("endpoint : ", endpoint);
      //  const token = getState().auth.token;
      try {
        let token = sessionStorage.getItem("Token");
        header.set("x-access-token", `${token}`);
      } catch (error) {
        console.log("error : ", error);
      }
      return header;
    },
  }),
  tagTypes: [
    "Spot",
    "getSpotSearch",
    "ReservationList",
    "updateProfile",
    "getslot",
    "GetProfile",
    "GetZipcode",
    "brandList",
    "Reservation",
    "favorite",
    "favoriteList",
    "createbrand",
  ],
  endpoints: (builder) => ({
    // Mutation

    userLogin: builder.mutation({
      query: (params) => {
        return {
          url: URLs.USER_LOGIN.URL,
          method: "POST",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    verifyOtp: builder.mutation({
      query: (params) => {
        return {
          url: URLs.USER_VERIFY.URL,
          method: "POST",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    ResendOtp: builder.mutation({
      query: (params) => {
        return {
          url: URLs.RESEND_OTP.URL,
          method: "POST",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    GetAlluser: builder.mutation({
      query: (params) => {
        return {
          url: URLs.GET_USERS.URL,
          method: "GET",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    GetAllContact: builder.mutation({
      query: (params) => {
        return {
          url: URLs.GET_CONTACT.URL,
          method: "GET",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    GetAllMessage: builder.mutation({
      query: (params) => {
        return {
          url: URLs.GET_MESSAGE.URL,
          method: "POST",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    GetStatus: builder.mutation({
      query: (params) => {
        return {
          url: URLs.GET_STATUS.URL,
          method: "GET",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    Preregister: builder.mutation({
      query: (params) => {
        return {
          url: URLs.PRE_REGISTER.URL,
          method: "POST",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    GetPreregister: builder.mutation({
      query: (params) => {
        return {
          url: URLs.PRE_REGISTER.URL,
          method: "GET",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    ResendPreregister: builder.mutation({
      query: (params) => {
        return {
          url: `${URLs.RESEND_PRE_REGISTER.URL}${params}`,
          method: "POST",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    DeleteContactRequest: builder.mutation({
      query: (params) => {
        return {
          url: `${URLs.DELETE_CONTACT_REQUEST.URL}${params}`,
          method: "DELETE",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    Archive: builder.mutation({
      query: (params) => {
        return {
          url: `${URLs.ARCHIVE_CONTACT.URL}${params}`,
          method: "PUT",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    GetArchive: builder.mutation({
      query: (params) => {
        return {
          url: URLs.GETARCHIVE_CONTACT.URL,
          method: "GET",
          body: params,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    GetEdit: builder.mutation({
      query: (id) => {
        console.log("🚀 ~ file: apiSlice.js ~ line 205 ~ id", id)
        return {
          url: `${URLs.EDIT_NOTICE.URL}${id.id}`,
          method: "PUT",
          body: id,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const {
  useUserLoginMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useGetAlluserMutation,
  useGetAllContactMutation,
  useGetAllMessageMutation,
  useGetStatusMutation,
  usePreregisterMutation,
  useGetPreregisterMutation,
  useResendPreregisterMutation,
  useDeleteContactRequestMutation,
  useArchiveMutation,
  useGetArchiveMutation,
  useGetEditMutation

} = apiSlice;
