import "./App.scss";
import Login from "./pages/admin/login";
import OtpForm from "./pages/admin/OtpForm";
import ContactRequest from "./pages/admin/contactRequest";
import PreRegistration from "./pages/admin/preregistration";
import ContactUs from "./pages/tookone/contactUs";
import Index from "./pages/tookone/index";
import LegalInfomation from "./pages/tookone/legalInformation";
import ShareLink from "./pages/tookone/shareLink";
import Tookenfooter from "./pages/tookone/Tookonefooter";
import GameManual from "./pages/game/GameManual";
import "./scss/style.scss";
import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Kickstarter from "./pages/game/Kickstarter";
import KickStarterPage from "./pages/game/KickStarterPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Award from "./pages/tookone/IconsComponent/Award";
import Gifts from "./pages/tookone/IconsComponent/Gifts";
import Pixels from "./pages/tookone/IconsComponent/Pixels";
import Intrest from "./pages/tookone/IconsComponent/Intrest";
import Inspirition from "./pages/tookone/IconsComponent/Inspirition";
import Hashs from "./pages/tookone/IconsComponent/Hashs";
import Hashtag from "./pages/tookone/IconsComponent/Hashtag";
import Information from "./pages/tookone/IconsComponent/Information";
import Loader from "./pages/admin/Loader";
import GetArchive from "./pages/admin/GetArchive";


function App() {
  return (
    <div>
      <ToastContainer autoClose={3000} style={{zIndex:"99999999"}}/>
      <Suspense fallback={null}>
        <BrowserRouter>
          <Routes>
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/login/:id" element={<OtpForm />} />
            <Route path="/admin/contactrequest" element={<ContactRequest />} />
            {/* <Route path="/admin/contactrequest/getarchive" element={<GetArchive />} /> */}
            <Route
              path="/admin/preregistration"
              element={<PreRegistration />}
            />
            <Route path="/tookone/contactus" element={<ContactUs />} />
            <Route path="/" element={<Index />} />
            <Route
              path="/tookone/legalinformation"
              element={<LegalInfomation />}
            />
            <Route path="/tookone/sharelink" element={<ShareLink />} />
            <Route path="/tookone/tookonrfooter" element={<Tookenfooter />} />
            <Route path="/game/gamemanual" element={<GameManual />} />
            <Route path="/game/kickstarter" element={<Kickstarter />} />
            <Route path="/game/kickstarterpage" element={<KickStarterPage />} />
            <Route path="/tookone/game/award" element={<Award />} />
            <Route path="/tookone/game/Gifts" element={<Gifts />} />
            <Route path="/tookone/game/Hashs" element={<Hashs />} />
            <Route path="/tookone/game/Hashtag" element={<Hashtag />} />
            <Route path="/tookone/game/Information" element={<Information />} />
            <Route path="/tookone/game/Inspirition" element={<Inspirition />} />
            <Route path="/tookone/game/Intrest" element={<Intrest />} />
            <Route path="/tookone/game/Pixels" element={<Pixels />} />
            <Route path="/loading" element={<Loader />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
