import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { FaHouseUser } from "react-icons/fa";
import { FaBan } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FaBuilding } from "react-icons/fa";
import { FaCarrot } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import Tookonrfooter from "../tookone/Tookonefooter";
import gamecard from "../../asset/images/game/gamecard-img.png";
import AdminHeader from "./../admin/adminHeader";

function GameManual() {
  const { t } = useTranslation();

  return (
    <div>
      <AdminHeader />
      <div className="page game-header">
        <div className="bg_shape"></div>
        <div className="background_img gamemanual_header">
        <div className="legalinformation container-xl">
              <h1 className="text-white text-uppercase">{t("game manual")}</h1>
            </div>
        </div>
            
      </div>
      <div className="container-xl">
        <div className="row gap-3 pb-3">
          <div className="col-md-4 col-12 pb-5">
            <div className="section-1">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="text-black dropdown d-flex align-items-center gap-3">
                      <FaUserAlt className="dropdown-icon" />
                      <p className="m-0">lorem epsum</p>
                    </div>
                  </Accordion.Header>
                  <div className="Accordion_body bg-light">
                    <Accordion.Body className="d-flex">
                      <span className="d-block px-2 me-2">1</span>
                      <a href="#1" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="d-flex">
                      <span className="d-block px-2 me-2">2</span>
                      <a href="#2"  className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="d-flex">
                      <span className="d-block px-2 me-2">3</span>
                      <a href="#3"  className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                  </div>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="text-black dropdown d-flex align-items-center gap-3">
                      <FaHouseUser className="dropdown-icon" />
                      <p className="m-0">lorem epsum</p>
                    </div>
                  </Accordion.Header>
                  <div className="Accordion_body bg-light">
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">1</span>
                      <a href="#1" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">2</span>
                      <a href="#2" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">3</span>
                      <a href="#3" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                  </div>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <div className="text-black dropdown d-flex align-items-center gap-3">
                      <FaBan className="dropdown-icon" />
                      <p className="m-0">lorem epsum</p>
                    </div>
                  </Accordion.Header>
                  <div className="Accordion_body bg-light">
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">1</span>
                      <a href="#1" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">2</span>
                      <a href="#2" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">3</span>
                      <a href="#3" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                  </div>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <div className="text-black dropdown d-flex align-items-center gap-3">
                      <FaBuilding className="dropdown-icon" />
                      <p className="m-0">lorem epsum</p>
                    </div>
                  </Accordion.Header>
                  <div className="Accordion_body bg-light">
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">1</span>
                      <a href="#1" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">2</span>
                      <a href="#2" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">3</span>
                      <a href="#3" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                  </div>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <div className="text-black dropdown d-flex align-items-center gap-3">
                      <FaCarrot className="dropdown-icon" />
                      <p className="m-0">lorem epsum</p>
                    </div>
                  </Accordion.Header>
                  <div className="Accordion_body bg-light">
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">1</span>
                      <a href="#1" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">2</span>
                      <a href="#2" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">3</span>
                      <a href="#3" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                  </div>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <div className="text-black dropdown d-flex align-items-center gap-3">
                      <FaRegClock className="dropdown-icon" />
                      <p className="m-0">lorem epsum</p>
                    </div>
                  </Accordion.Header>
                  <div className="Accordion_body bg-light">
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">1</span>
                      <a href="#1" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">2</span>
                      <a href="#2" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">3</span>
                      <a href="#3" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                  </div>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <div className="text-black dropdown d-flex align-items-center gap-3">
                      <FaDownload className="dropdown-icon" />
                      <p className="m-0">lorem epsum</p>
                    </div>
                  </Accordion.Header>
                  <div className="Accordion_body bg-light">
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">1</span>
                      <a href="#1" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">2</span>
                      <a href="#2" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                    <Accordion.Body className="text-break d-flex">
                      <span className="d-block px-2">3</span>
                      <a href="#3" className="text-decoration-none">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      </a>
                    </Accordion.Body>
                  </div>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-md-7 col-12 md-pt-3">
            <div className="section-2">
              <div className="icon">
                <h3>
                  <FaUserAlt /> Lorem epsum
                </h3>
              </div>
              <div className="contain pt-3">
                <h6 className="fw-bold" id="1">
                  1. Lorem ipsum dolor sit amet, consetetur sadipscing elitr ,
                </h6>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
                <div className="game-card">
                  <img src={gamecard} alt="" />
                  <img src={gamecard} alt="" />
                </div>
              </div>

              <div className="contain pt-3">
                <h6 className="fw-bold" id="2">
                  2. Lorem ipsum dolor sit amet, consetetur sadipscing elitr ,
                </h6>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
              </div>

              <div className="contain pt-3">
                <h6 className="fw-bold" id="3">
                  3. Lorem ipsum dolor sit amet, consetetur sadipscing elitr ,
                </h6>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tookonrfooter />
    </div>
  );
}

export default GameManual;
