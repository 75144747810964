
export const DEVELOPMENT_MODE_BASEURL = "https://api.tookme.de/api/v1/";
export const URLs = {
  USER_LOGIN: {
    URL: "/auth/login",
  },
  USER_VERIFY: {
    URL: "/auth/verifyOtp",
  },
  RESEND_OTP: {
    URL: "/auth/resend",
  },
  GET_USERS: {
    URL: "/user/users",
  },
  GET_CONTACT: {
    URL: "/contact/contact",
  },
  GET_MESSAGE: {
    URL: "/contactus/contact",
  },
  GET_STATUS: {
    URL: "/auth/isadmin",
  },
  PRE_REGISTER :{
    URL : "/preregister/preregister"
  },
  RESEND_PRE_REGISTER : {
    URL : "/preregister/resendpreregister/"
  },
  DELETE_CONTACT_REQUEST : {
    URL : "/contact/"
  },
  ARCHIVE_CONTACT : {
    URL : "/contact/archive/"
  },
  EDIT_NOTICE : {
    URL : "/contact/notice/"
  },
  

};

export const GetBaseURL = () => {
  return DEVELOPMENT_MODE_BASEURL
    
};
