import React from "react";
import { FaAt } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import AdminHeader from "./adminHeader";
import Blue_Network from "../../asset/icons/blue_network.svg";
import WhiteCloudkIcon from "../../asset/icons/cloud_whitebg.svg";
import user_icon from "../../asset/icons/user-2.svg";
import Eye_icon from "../../asset/icons/eye.svg";
import Trash from "../../asset/icons/Trash.svg";
import user_icon_black from "../../asset/icons/user.svg";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetPreregisterMutation } from "../../redux/services/apiSlice";
import { useResendPreregisterMutation } from "../../redux/services/apiSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PrivateRoute from "../../Components/PrivateRoute";
import { toast } from "react-toastify";

function Preregistration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { _id } = params;

  console.log(params,"paramsparams");
  const [
    paramsAlluser,
    {
      data: GetallUserData,
      isLoading: isLoadingGetallUser,
      error: errorGetallUser,
      isError: isErrorGetallUser,
      isSuccess: isSuccessGetallUser,
    },
  ] = useGetPreregisterMutation();

  const [
    paramsResendPreregister,
    {
      data: ResendData,
      isLoading: isLoadingResend,
      error: errorResend,
      isError: isErrorResend,
      isSuccess: isSuccessResend,
      reset: Resendotp,
    },
  ] = useResendPreregisterMutation();

  const getalldata = async (data) => {
    paramsAlluser(data);
  };

  useEffect(() => {
    getalldata();
  }, []);

  const countuser = (data) => {
    let paramToReturn = 0;
    for (let i = 0; i <= GetallUserData?.length; i++) {
      if (GetallUserData[i]?.status === data) {
        paramToReturn += 1;
      }
    }
    return paramToReturn;
  };

  console.log(GetallUserData,"GetallUserDataGetallUserDataGetallUserData");

  const handlexrm = () => {
    navigate("/admin/contactrequest");
  };
  const handleClick = () =>{
    navigate("/game/kickstarter")
  }
  const handleReset = (_id) => {
    paramsResendPreregister(_id)
    console.log("clicked",_id);
  }


  useEffect(()=>{
    if(isSuccessResend){
      toast.success("Verification Link Resend Suceessfully")
    }
  })




  return (
    <PrivateRoute>
      <div>
        <AdminHeader />
        <div className="admin-container">
          <div className="header">
            <div className="container-fluid">
              <div className="row w-100 border-bottom border-dark">
                <div className="header-icon d-flex justify-content-between align-items-center">
                  <div className="col-9 col-md-5">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="menu-bar text-white d-lg-none d-block">
                        <FaBars />
                      </div>
                      <div className="header_icon">
                        <img src={Blue_Network} alt="Blue_Network" />
                      </div>
                      <div className="header_icon Cloudkicon">
                        <img src={WhiteCloudkIcon} alt="CloudkIcon" />
                        <h3 className="icontext" onClick={handlexrm}>
                          {t("XRM")}
                        </h3>
                      </div>
                  </div>
                  </div>
                  <div className="col-3 col-md-5">
                    <div className="d-flex flex-xl-row-row flex-xl-reverse">
                  <img src={user_icon} onClick={handleClick} alt="user_icon" className="user-icon"/> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="buttons registration-btn">
              <div className="row">
              <div className="col-12 col-md-4">
                <div className="first-button d-flex align-items-center justify-content-between ">
                  {countuser("active")} {t("active")}
                  <span className="mobile-icon"></span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="second-button d-flex align-items-center justify-content-md-between justify-content-center">
                  {countuser("inactive")} {t("inactive")}
                  <img src={Eye_icon} width="50px" alt="contect icon" />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="thread-button d-flex align-items-center justify-content-md-between justify-content-center">
                  97 {t("deleted")}
                  <img src={Trash} alt="contect icon" />
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="table ">
              <table className="tableFixHead">
                <thead >
                  <tr >
                    <th>{t("ID")}</th>
                    <th>{t("Email")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Funcation and Status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoadingGetallUser &&
                    isSuccessGetallUser &&
                    GetallUserData?.map((item, index) => {
                      // countuser(item)
                      return (
                        <tr className="table-row-1 " key={index}>
                          <td>{index + 1}</td>
                          <td>{item.email}</td>
                          <td>
                            {moment(item.dateofbirth).format("DD-MMM-YYYY")}
                          </td>
                          <td className="status">
                          
                            {item.status === "active" ? (
                              <p className="my-0 active-status">
                                {item.status}
                              </p>
                      
                            ) : item.status === "inactive" ? (
                              <p className="my-0 inactive-status">
                                {item.status}
                              </p>
                            ) : (
                              <p className="my-0 delete-status">
                                {item.status}
                              </p>
                            )}
                            {item.status === "inactive" ? (<button onClick={()=>handleReset(item._id)} className="resend-button">{t("Resend")}</button>) : null}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {/* <div class="table-responsive ">
                    <table class="table table-fixed">
                        <thead>
                            <tr>
                                <th scope="col" class="col-3">{t("ID")}</th>
                                <th scope="col" class="col-3">{t("Email")}</th>
                                <th scope="col" class="col-3">{t("Date")}</th>
                                <th scope="col" class="col-3">{t("Funcation and Status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {!isLoadingGetallUser &&
                    isSuccessGetallUser &&
                    GetallUserData?.map((item, index) => {
                      // countuser(item)
                      return (
                        <tr scope="row" className="table-row-1 " key={index}>
                          <td class="col-3">{index + 1}</td>
                          <td class="col-3">{item.email}</td>
                          <td class="col-3">
                            {moment(item.dateofbirth).format("DD-MMM-YYYY")}
                          </td>
                          <td className="status col-3">
                          
                            {item.status === "active" ? (
                              <p className="my-0 active-status">
                                {item.status}
                              </p>
                      
                            ) : item.status === "inactive" ? (
                              <p className="my-0 inactive-status">
                                {item.status}
                              </p>
                            ) : (
                              <p className="my-0 delete-status">
                                {item.status}
                              </p>
                            )}
                            {item.status === "inactive" ? (<button onClick={()=>handleReset(item._id)} className="resend-button">{t("Resend")}</button>) : null}
                          </td>
                        </tr>
                      );
                    })}
                           
                        </tbody>
                    </table>
                </div> */}
          </div>
        </div>
        <div className="admin-sidebar-container d-lg-block d-none">
          <div className="container-fluid">
            <div className="border-bottom">
              <div className="row align-center pt-2 pb-1">
                <div className="col-xl-3 col-12">
                  <div className="use-icon d-xl-block d-flex justify-content-center align-items-center">
                    <img src={user_icon} alt="user_icon" />
                  </div>
                </div>
                <div className="col-xl-9 logo-text col-12">
                  <p className="text-white text-center pe-2">
                    {t("Pre-registrations")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrivateRoute>
  );
}

export default Preregistration;
